import React, { useEffect } from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import moduleStyles from '../styles/pages/404.module.scss'
import LazyResponsiveImage from 'react-lazy-responsive-image';
import bgS from '../../static/assets/images/404/calorex_404_oops_s.png'
import bgM from '../../static/assets/images/404/calorex_404_oops_m.png'
import bgLg from '../../static/assets/images/404/calorex_404_oops_xl.png'

const NotFoundPage = () =>{ 

  useEffect(()=>{
	},[])

  return (
    <>
      <SEO title="404: Not found" />
      <div className={moduleStyles.container__404}>
        <div className={moduleStyles.main__text__container}>
          <div className={moduleStyles.content__title}><h1 className={moduleStyles.content__title}>Lo sentimos, página no encontrada.</h1></div>
          <LazyResponsiveImage
                  src={bgLg}
                  className={moduleStyles.oops__img}
                  sources={{
                    sm: bgS,
                    md: bgM,
                    lg: bgLg,                    
                  }}
                  breakpoints={{
                    sm: 767,
                    md: 1023,
                    lg: 1919
                  }}/>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage
